import actions from './actions'
import mutations from './mutations'

export default {
	namespaced: true,
	state: {
		all: [],
		single : null
	},
	getters: {
		all: state => state.all,
		single: state => state.single
  },
  mutations: mutations,
	actions: actions,
}
