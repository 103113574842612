import SurveySectionApi from '@/services/api/admin/SurveySectionApi'
import mixinGetters from  '@/store/mixins/survey/showing/getters'
import mixinMutations from '@/store/mixins/survey/showing/mutations'
import mixinActions from '@/store/mixins/survey/showing/actions'

export default {
	namespaced: true,
	state: {
		name: '',
		fetchingViewrData: false,
		template_option: 'new',
		data: {},
		segments: {},
		fields: {},
		options: {},
		jumps: {},
		jumpRuleGroups: {},
		jumpRules: {}
	},
	getters: {
		...mixinGetters,
		name: state => state.name,
		templateOption: state => state.template_option,
		fetchingViewerData: state => state.fetchingViewerData,
		data: state => state.data
  },
  mutations: {
		...mixinMutations,
		/**
		 * Edit value
		 * @param {Object} payload 
		 */
		editName(state, payload) {
			state.name = payload
		},
		/**
		 * Set template option for survey
		 * @param {String} option 
		 */
		setTemplateOption(state, option) {
			state.template_option = option
		},
		/**
		 * Set if is fetching data for template or survey
		 * @param {Boolean} payload 
		 */
		setFetchingViewerData(state, payload) {
			state.fetchingViewerData = payload	
		},
  },
  actions: {
		...mixinActions,
		/**
		 * Store a new survey section 
		 */
		async store ({ state, commit }, ) {
			try {
				const section = await SurveySectionApi.store({
					name: state.name,
					template_option: state.template_option,
					chosen_template: state.data.id 
				})
				return section
			} catch (err) {
				throw err
			}
		},
		/**
		 * Get requested surveySection
		 * @param {String} 
		 */
		async showSurveySection ({ dispatch, commit }, id) {
			try {
				commit('setFetchingViewerData', true)
				const surveySection = await SurveySectionApi.show(id)
				dispatch('setSurveyData', surveySection)
			} catch (err) {
				console.log(err)
			} finally {
				commit('setFetchingViewerData', false)
			}
		},
	}
}
