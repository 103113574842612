import mixinGetters from '@/store/mixins/option/getters'

export default {
	namespaced: true,
	state: {
		all: {},
		removed: [],
		notValid: {
			fi: [],
			en: []
		}
	},
	getters: {
		editSource (state, getters, rootState, rootGetters) {
			return rootGetters.editSources.commonSegment
		},
		...mixinGetters,
	},
	mutations: {
		setOptions(state, options) {
			state.all = options
		}
	},
}
