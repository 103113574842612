import StandardizationFieldsApi from '@/services/api/admin/StandardizationFieldsApi'

export default {
	/**
	 * Get all non-admin fields 
	 */
	async all ({ commit }, ) {
		try {
			const standardizationFields = await StandardizationFieldsApi.all()
			commit('setAllStandardizationFields', standardizationFields)
		} catch (err) {
			throw err
		}	
	}
}