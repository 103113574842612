import CompilationsApi from '@/services/api/admin/CompilationsApi'

export default {
	/**
	 * Get all compilations
	 */
	 async all ({ commit }, ) {
		try {
			const compilations = await CompilationsApi.all()
			commit('setCompilations', compilations)
		} catch (err) {
			throw err
		}	
	},
	async single (id,{ commit }, ) {
		try {
			const compilation = await CompilationsApi.show(id)
			commit('setCompilation', compilation)
		} catch (err) {
			throw err
		}	
	}
}