export default {
	/**
	 * Set user's data for editing
	 * @param {Object} user 
	 */
	setData(state, user) {
		state.data = user
	},
	/**
	 * Edit user's value
	 * @param {Object} payload 
	 */
	editValue(state, payload) {
		state.data = {
			...state.data,
			[payload.key]: payload.value
		}
	},
}
