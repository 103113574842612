import mutations from './mutations'
import actions from './actions'

export default {
	namespaced: true,
	state: {
		transport: {
		  translations: {
		    en: {
		      name: null
		    },
		    fi: {
		      name: null
		    }
		  },
		  icon: null,
		  emission: null
		},
		option: {
		  translations: {
		    en: {
		      name: null
		    },
		    fi: {
		      name: null
		    }
		  },
		  emission: null
		}
	},
	getters: {
		transport: state => state.transport,
		option: state => state.option
  },
  mutations: mutations,
  actions: actions
}
