import Http from '@/services/AdminHttp'

export default {
	async all () {
		try {
			const response = await Http.get('api/admin/compilations') 
			return response.data
		} catch (err) {
			throw err
		}
	},
	async tableData () {
		try {
			const response = await Http.get('api/admin/compilations/table') 
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Get compilation template by id
	 *
	 * @param {String} id uuid
	 * @return {Object} 
	 */
	 async show (id) {
		try {
			const response = await Http.get(`api/admin/compilations/${id}`) 
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Create a new resource
	 *
	 * @param {Object} 
	 * @return {Object} 
	 */
		 async store (data) {
			try {
				const response = await Http.post('api/admin/compilations', data)
				return response.data
			} catch (err) {
				throw err
			}
		},
			/**
	 * rerun data
	 *
	 * @return {Object} 
	 */
		async runData () {
			try {
				const response = await Http.post('api/admin/compilations/dataview/update')
				return response.data
			} catch (err) {
				throw err
			}
		},
	/**
	 * update resource
	 *
	 * @param {Object} 
	 * @return {Object} 
	 */
		 async update (data,id) {
			try {
				const response = await Http.put(`api/admin/compilations/${id}`, data)
				return response.data
			} catch (err) {
				throw err
			}
		},
	/**
	 * delete resource
	 *
	 * @param {Object} 
	 * @return {Object} 
	 */
		 async remove (id) {
			try {
				const response = await Http.delete(`api/admin/compilations/${id}`)
				return response.data
			} catch (err) {
				throw err
			}
		},
	/**
	 * Create a new resource
	 *
	 * @param {Object} 
	 * @return {Object} 
	 */
		 async createNewField (data,id) {
			try {
				const response = await Http.post(`api/admin/compilations/${id}/new_field`, data)
				return response.data
			} catch (err) {
				throw err
			}
		},

	/**
	 * update a new resource
	 *
	 * @param {Object} 
	 * @return {Object} 
	 */
		 async updateFieldValues (data) {
			try {
				const response = await Http.post('api/admin/compilations/update_fields', data)
				return response.data
			} catch (err) {
				throw err
			}
		},
	/**
	 * update resource
	 *
	 * @param {Object} 
	 * @return {Object} 
	 */
	 async updateFieldName (data) {
		try {
			const response = await Http.post('api/admin/compilations/update_field', data)
			return response.data
		} catch (err) {
			throw err
		}
	},
		/**
	 * update resource
	 *
	 * @param {Object} 
	 * @return {Object} 
	 */
		 async deleteFieldName (data) {
			try {
				const response = await Http.post('api/admin/compilations/delete_field', data)
				return response.data
			} catch (err) {
				throw err
			}
		},
}
