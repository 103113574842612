import Http from '@/services/AdminHttp'

export default {
  async all () {
    try {
      const response = await Http.get('api/admin/static_translations') 

      // Normalize
      const translated = response.data.map(static_trans => {
        let obj = {...static_trans}
        let translations = {}

        static_trans.translations.forEach(translation => {
          translations = {
            ...translations,
            [translation.locale]: {
              value: translation.value
            }
          }
        })

        return {
          ...obj,
          translations: translations,
        }
      })

      return translated
    } catch (err) {
      throw err
    }
  },
  async update(data) {
    try {
      await Http.patch(`api/admin/static_translations`, data)
    } catch (err) {
      throw err
    }
  },
}
