export default {
	/**
	 * Set of list section type ids
	 * @param {Array} types 
	 */
	setTypesList(state, types) {
		state.target_types = types 
	},
	/**
	 * Add user to section 
	 * @param {String}  user_id 
	 */
	addUser(state, user_id) {
		if (!state.users.includes(user_id)) {
			state.users.push(user_id)
		}
	},
	/**
	 * Add target type to section
	 * @param {String} targetTypeId 
	 */
	addTargetType(state, targetTypeId) {
		if (!state.target_types.includes(targetTypeId)) {
			state.target_types.push(targetTypeId)
		}
	},
	/**
	 * Remove user from section 
	 * @param {String}  user_id 
	 */
	removeUser(state,  user_id) {
		const index = state.users.indexOf(user_id)
		if (index !== -1) {
			state.users.splice(index, 1)
		}
	},
}
