import Http from '@/services/AdminHttp'

export default {
	async all () {
		try {
			const response = await Http.get('api/admin/transports') 

			// Normalize
			const translated = response.data.map(transport => {
			  let obj = {...transport}
			  let translations = {
			    en: {
			      name: null
			    },
			    fi: {
			      name: null
			    },
			  }


			  transport.translations.forEach(translation => {
			    translations = {
			      ...translations,
			      [translation.locale]: {
			        name: translation.name
			      }
			    }
			  })

			  let options = transport.options.map(option => {
			    let option_translations = {}

			    option.translations.forEach(translation => {
			      option_translations = {
			        ...option_translations,
			        [translation.locale]: {
			          name: translation.name
			        }
			      }
			    })

			    return {
			      ...option,
			      translations: option_translations
			    }
			  })


			  return {
			    ...obj,
			    translations: translations,
			    options: options
			  }
			})
    
		  return translated
		} catch (err) {
			throw err
		}
	},
	/**
	 * Get transport by id
	 *
	 * @param {String} id uuid
	 * @return {Object} 
	 */
	async show (id) {
		try {
			const response = await Http.get(`api/admin/transports/${id}`) 
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Create a new transport
	 *
	 * @param {Object} 
	 * @return {Object} 
	 */
	async store (transport) {
		try {
			const response = await Http.post('api/admin/transports', transport)

			let translations = {
			  en: {
			    name: null
			  },
			  fi: {
			    name: null
			  },
			}

      response.data.translations.forEach(translation => {
        translations = {
          ...translations,
          [translation.locale]: {
            name: translation.name
          }
        }
      })

			return {
        ...response.data,
        translations: translations
			}
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update transport's data
	 *
	 * @param {String} id
	 * @param {Object} data
	 * @return {Object} 
	 */
	async update (id, transport) {
		try {
			const response = await Http.patch(`api/admin/transports/${id}`, transport)

			let translations = {
			  en: {
			    name: null
			  },
			  fi: {
			    name: null
			  },
			}

      response.data.translations.forEach(translation => {
        translations = {
          ...translations,
          [translation.locale]: {
            name: translation.name
          }
        }
      })

			let options = response.data.options.map(option => {
			  let option_translations = {}

			  option.translations.forEach(translation => {
			    option_translations = {
			      ...option_translations,
			      [translation.locale]: {
			        name: translation.name
			      }
			    }
			  })

			  return {
			    ...option,
			    translations: option_translations
			  }
			})

			return {
        ...response.data,
        translations: translations,
        options: options
			}
		} catch (err) {
			throw err
		}
	},

	/**
	 * Delete chosen transport
	 *
	 * @param {String} id
	 */
	async delete (id) {
		try {
			return await Http.delete(`api/admin/transports/${id}`)
		} catch (err) {
			throw err
		}
	},

  /**
   * Update transports orders
   *
   * @param {Array} data
   */
	async updateOrders (data) {
	  try {
	    const response = await Http.patch('api/admin/transports/orders', data)
	    return response.data
	  } catch (err) {
	    throw err
	  }
	},

  /**
   * Update transport options orders
   *
   * @param {Array} data
   */
	async updateOptionOrders (data) {
	  try {
	    const response = await Http.patch('api/admin/transports/option-orders', data)
	    return response.data
	  } catch (err) {
	    throw err
	  }
	},
}
