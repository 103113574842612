import actions from './actions'
import editing from './editing'

export default {
	namespaced: true,
	state: {
		all: [],
		creating: {
			name: null
		},
	},
	getters: {
		all: state => state.all,
		creating: state => state.creating
  },
  mutations: {
		/**
		 * Set frames
		 * @param {Array} frames 
		 */
		setFrames(state, frames) {
			state.all = frames
		},
		/**
		 * Edit creating frame's value
		 * @param {Object} payload 
		 */
		editCreatingValue(state, payload) {
			state.creating = {
				...state.creating,
				[payload.key]: payload.value
			}	
		},
  },
  actions: actions,
	modules: {
		editing: editing
	}
}
