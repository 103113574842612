import actions from './actions'
import mutations from './mutations'
import creating from './creating'
import editing from './editing'

export default {
	namespaced: true,
	state: {
		all: []
	},
	getters: {
		all: state => state.all
  },
  mutations: mutations,
	actions: actions,
	modules: {
		creating: creating,
		editing: editing
	}
}
