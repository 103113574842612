import AdminFieldsApi from '@/services/api/admin/AdminFieldsApi'

export default {
	/**
	 * Get all non-admin fields 
	 */
	async all ({ commit }, ) {
		try {
			const adminFields = await AdminFieldsApi.all()
			commit('setAdminFields', adminFields)
		} catch (err) {
			throw err
		}	
	}
}