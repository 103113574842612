import Http from '@/services/AdminHttp'

export default {
	async all () {
		try {
			const response = await Http.get('api/admin/standardization_fields') 
			return response.data
		} catch (err) {
			throw err
		}
	},
	  /**
   * rerun data
   *
   * @return {Object}
   */
	  async rerunData() {
		try {
		  const response = await Http.post("api/admin/standardization_fields/dataview/update");
		  return response.data;
		} catch (err) {
		  throw err;
		}
	  },
			/**
	 * update a new resource
	 *
	 * @param {Object} 
	 * @return {Object} 
	 */
	async updateValue (data,id) {
		try {
			const response = await Http.post(`api/admin/standardization_fields/${id}`,data) 
			return response.data
		} catch (err) {
			throw err
		}
	},
}
