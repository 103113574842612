import axios from 'axios'
import store from '@/store/admin'
import router from '@/router/admin'

const http = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	withCredentials: true
})

export default http

http.interceptors.response.use(function (response) {
    return response;
  }, function (error) {        
    if (error.response.status == 401) {        
				store.dispatch('auth/signOut')
				router.push( {path: '/kirjaudu'} )
        store.dispatch('notification/showNotifications', [{
          type: 'warning',
          text: 'Istunto vanhentunut'
        }])
		} else if (error.response.status === 500) {
        store.dispatch('notification/showNotifications', [{
          type: 'error',
          text: 'Virhe palvelimella'
        }])
		} else if (error.response.status === 403) {
        store.dispatch('notification/showNotifications', [{
          type: 'warning',
          text: 'Toiminto kielletty'
        }])
				store.dispatch('auth/me')
		} else {

		}
  });
