import Http from '@/services/AdminHttp'

export default {
	async all () {
		try {
			const response = await Http.get('api/admin/survey_groups') 
			return response.data
		} catch (err) {
			throw err
		}
	},
	async single (id) {
		try {
			const response = await Http.get(`api/admin/survey_groups/${id}`) 
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Create a new common segment
	 *
	 * @param {Object} 
	 * @return {Object} 
	 */
	async store (data) {
		try {
			const response = await Http.post('api/admin/survey_groups', data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update common segment and it's field and options
	 *
	 * @param {String} id id of the segment
	 * @param {Object} data segment, fields and options 
	 * @return {Object}
	 */
	 async update (id, data) {
		try {
			const response = await Http.patch(`api/admin/survey_groups/${id}`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update common segment and it's field and options
	 *
	 * @param {String} id id of the segment
	 * @param {Object} data segment, fields and options 
	 * @return {Object}
	 */
	async updateLanguages (id, data) {
		try {
			const response = await Http.patch(`api/admin/survey_groups/${id}/languages`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update common segment and it's field and options
	 *
	 * @param {String} id id of the segment
	 * @param {Object} data segment, fields and options 
	 * @return {Object}
	 */
	async publishGroup (id, data) {
		try {
			const response = await Http.patch(`api/admin/survey_groups/${id}/publish`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},
		/**
	 * Update common segment and it's field and options
	 *
	 * @param {String} id id of the segment
	 * @return {Object}
	 */
		 async unPublishGroup (id) {
			try {
				const response = await Http.patch(`api/admin/survey_groups/${id}/unPublish`)
				return response.data
			} catch (err) {
				throw err
			}
		},
	/**
	 * Update common segment and it's field and options
	 *
	 * @param {String} id id of the segment
	 * @param {Object} data segment, fields and options 
	 * @return {Object}
	 */
	async updateSettings (id, data) {
		try {
			const response = await Http.patch(`api/admin/survey_groups/${id}/settings`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Delete chosen common segment
	 *
	 * @param {String} id
	 */
	async delete (id) {
		try {
			const response =  await Http.delete(`api/admin/survey_groups/${id}`)
			return response.data
		} catch (err) {
			throw err
		}
	},
}
