import SurveysApi from '@/services/api/admin/SurveysApi'

export default {
	/**
	 */
	async all ({ commit }, ) {
		try {
			const surveys = await SurveysApi.all()
			commit('setSurveys', surveys)
		} catch (err) {
			throw err
		}	
	}
}