import Http from "@/services/AdminHttp";

export default {
  async all() {
    try {
      const response = await Http.get("api/admin/all_fields");
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  /**
   * rerun data
   *
   * @return {Object}
   */
  async runData() {
    try {
      const response = await Http.post("api/admin/all_fields/dataview/update");
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  /**
   * Create a new resource
   *
   * @param {Object}
   * @return {Object}
   */
  async createNewField(data) {
    try {
      const response = await Http.post("api/admin/all_fields/new_field", data);
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  /**
   * update resource
   *
   * @param {Object}
   * @return {Object}
   */
  async updateFieldName(data) {
    try {
      const response = await Http.post("api/admin/all_fields/update_field", data);
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  /**
   * update resource
   *
   * @param {Object}
   * @return {Object}
   */
  async deleteFieldName(data) {
    try {
      const response = await Http.post("api/admin/all_fields/delete_field", data);
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  /**
   * update a new resource
   *
   * @param {Object}
   * @return {Object}
   */
  async updateFieldValues(data) {
    try {
      const response = await Http.post("api/admin/all_fields/update_fields", data);
      return response.data;
    } catch (err) {
      throw err;
    }
  },
};
