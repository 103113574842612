import mixinMutations from '@/store/mixins/common-segment/mutations'
import mixinActions from '@/store/mixins/common-segment/actions'
import field from './field'
import option from './option'
import editing from './editing'

export default {
	namespaced: true,
	state: {
		all: {},
		notValid: {
			fi: false,
			en: false
		},
		creating: {
			title: ''
		}
	},
	getters: {
		all: state => Object.keys(state.all).map(key => state.all[key]),
		get: (state) => (id) => state.all[id],
		creating: state => state.creating,
		hasInvalidValue: (state) => (locale, segment_id) => {
			if(!state.notValid.hasOwnProperty(locale)) return false
			return state.notValid[locale]
		},
		hasSomeInvalidValue (state) {
			let hasInvalidValues =  false
			Object.values(state.notValid).forEach(value => {
				if (value) hasInvalidValues = true
			})
			return hasInvalidValues
		},
		hasInvalidValues (state, getters) {
		const hasInvalidFields = getters['field/hasInvalidValues']
		const hasInvalidOptions = getters['option/hasInvalidValues']
		return getters['hasSomeInvalidValue'] || hasInvalidFields || hasInvalidOptions
		},
  },
	mutations: {
		...mixinMutations
	},
	actions: {
		...mixinActions
	},
	modules: {
		field: field,
		option: option,
		editing: editing
	}
}
