import SurveySectionApi from '@/services/api/admin/SurveySectionApi'
import Normalizer from '@/services/Normalizer'

export default {
	/**
	 * Get requested survey part
	 * @param {String} 
	 */
	async getData ({ dispatch, commit }, id) {
		commit('setLoading', true)
		try {
			const section = await SurveySectionApi.show(id)
			dispatch('setSurveyData', section)
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	/**
	 * TODO Problem with chrome?
	 * Set autfocus for segment title and remove it
	 */
	focusTitle ({ commit }) {
		commit('toggleTitleFocus', true)
		setTimeout(() => {
			commit('toggleTitleFocus', false)
		}, 500)
	},
	/**
	 * Update template's data 
	 * @param {Object} data
	 */
	async updateSurveyData ({ state, commit }) {
		const surveyData = await SurveySectionApi.update(state.data.id, state.data)
		//TODO return and set
	},
	/**
	 * Update template's segments, fields and options 
	 */
	async updateParts ({ state, commit, dispatch  }) {
		const data = {
			segments: state.segment.all,
			segment_order_list: state.segment.segmentList,
			removed_segments: state.segment.removed,
			fields: state.field.all,
			removed_fields: state.field.removed,
			options: state.option.all,
			removed_options: state.option.removed,
			jumps: state.jump.all,
			removed_jumps: state.jump.removed,
			rule_groups: state.jump.ruleGroup.all,
			removed_rule_groups: state.jump.ruleGroup.removed,
			rules: state.jump.rule.all,
			removed_rules: state.jump.rule.removed
		}
		dispatch(`field/removeExtraOptions`)
		const section = await SurveySectionApi.updateParts(state.data.id, data)
		dispatch('setSurveyData', section)
	},
	/**
	 * Get sections' target types 
	 * @param {String} section_id
	 */
	async getTypes ({ state, commit }) {
		try {
			const types = await SurveySectionApi.types(state.data.id)
			let typesIds = types.map(type => type.id)
			commit('setTypesList', typesIds)
		} catch (err) {
			throw err
		}	
	},
	/**
	 * Get sections' target types
	 * @param {String} section_id
	 */
	async updateTypes ({ state, commit }) {
		try {
			const targetTypes = await SurveySectionApi.updateTypes(state.data.id, {
				target_types: state.target_types
			})
			let targetTypesIds = targetTypes.map(type => type.id)
			commit('setTypesList', targetTypesIds)
		} catch (err) {
			throw err
		}	
	},
}
