import mutations from './mutations'
import actions from './actions'

export default {
	namespaced: true,
	state: {
		all: [],
		creating: {
			name: ''
		},
		editing: null 
	},
	getters: {
		all: state => state.all,
		creating: state => state.creating,
		editing: state => state.editing
  },
  mutations: mutations,
  actions: actions 
}
