export default {
	/**
	 * Edit user's value
	 * @param {Object} payload 
	 */
	editValue(state, payload) {
		state.data = {
			...state.data,
			[payload.key]: payload.value
		}
	},
}
