import user from './user'
import surveySection from './survey-section'
import commonSegment from './common-segment'
import category from './category'
import targetType from './target-type'
import frame from './frame'
import transport from './transport'
import language from './language'
import staticTranslations from './static-translations'
import surveyGroups from './survey-groups'
//HALLINTATAULUKKO
import adminFields from './admin-fields'
import subscriberFields from './subscriber-fields'
import allFields from './all-fields'
import standardizationFields from './standardization-fields'
import answers from './answers'
import compilations from './compilations'
import surveys from './surveys'

export default {
	surveySection: surveySection,
	commonSegment: commonSegment,
	category: category,
	user: user,
	targetType: targetType,
	frame: frame,
	transport: transport,
	language: language,
	staticTranslations: staticTranslations,
	surveyGroups: surveyGroups,
	surveys: surveys,
	
	//HALLINTATAULUKKO
	adminFields:adminFields,
	subscriberFields:subscriberFields,
	allFields:allFields,
	answers:answers,
	compilations:compilations,
	surveys:surveys,
	standardizationFields:standardizationFields
}
