import TransportApi from '@/services/api/admin/TransportApi'
import TransportOptionApi from '@/services/api/admin/TransportOptionApi'

export default {
	/**
	 * Set user's data 
	 * @param {Object} user
	 */
	setEditingTransport ({ commit }, transport) {
		commit('setEditingTransport', transport)
	},
	setEditingTransportOptions ({ commit }, options) {
		commit('setEditingTransportOptions', options)
	},
	setEditingTransportOption ({ commit }, option) {
		commit('setEditingTransportOption', option)
	},

	/**
	 * Update transport's data
	 */
	async update ({ state, commit }) {
		try {
			 return await TransportApi.update(state.transport.id, state.transport)
		} catch (err) {
			throw err
		}	finally {
		}
	},

	async updateOption ({ state, commit }) {
		try {
			 return await TransportOptionApi.update(state.transport.id, state.option)
		} catch (err) {
			throw err
		}	finally {
		}
	},

	async updateOptionsOrder ({ state, commit }) {
	  try {
	    let options = state.transport.options.map((option, index) => {
	      return {
          id: option.id,
          order: index
	      }
	    })
	    return await TransportApi.updateOptionsOrder(state.transport.id, options)
	  }catch (err) {
	    throw err
	  } finally {
	  }
	},

	async removeTransportOption ({ state, commit }, option_id) {
	  try {
	    const options = TransportOptionApi.delete(state.transport.id, option_id)
	    console.log(options)
	    return options
	  } catch (err) {
      throw err
	  } finally {
	  }
	}
}
