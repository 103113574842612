export default {
	/**
	 * Set user's transport for editing
	 * @param {Object} user 
	 */
	setEditingTransport(state, transport) {
		state.transport = transport
	},
	setEditingTransportOptions(state, options) {
		state.transport.options = options
	},
	setEditingTransportOption(state, option) {
		state.option = option
	},

	/**
	 * Edit user's value
	 * @param {Object} payload 
	 */
	editValue(state, payload) {
		state.transport = {
			...state.transport,
			[payload.key]: payload.value
		}
	},

	editTranslationValue(state, payload) {
	  state.transport = {
	    ...state.transport,
	    translations: {
	      ...state.transport.translations,
	      [payload.locale]: {
	        name: payload.value
	      }
	    }
	  }
	},
	editOptionValue(state, payload) {
		state.option = {
			...state.option,
			[payload.key]: payload.value
		}
	},

	editOptionTranslationValue(state, payload) {
	  state.option = {
	    ...state.option,
	    translations: {
	      ...state.option.translations,
	      [payload.locale]: {
	        name: payload.value
	      }
	    }
	  }
	}
}
