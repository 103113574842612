export default {
	/**
	 * Set all compilations
	 * @param {Array} compilations 
	 */
	 setCompilations(state, compilations) {
		state.all = compilations
	},
		/**
	 * Set all compilations
	 * @param {} compilation 
	 */
		 setCompilation(state, compilation) {
			state.single = compilation
		},
}
