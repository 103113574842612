import TransportApi from '@/services/api/admin/TransportApi'

export default {
	/**
	 * Get all non-admin users 
	 */
	async all ({ commit }, ) {
		try {
			const transports = await TransportApi.all()
			if(transports.length) {
			  commit('setTransports', transports)
			}else {
			  commit('setTransports', [])
			}
		} catch (err) {
			throw err
		}	
	},

	/**
	 * Remove user 
	 */
	async updateOrders ({}, transports) {
		try {
		  const filteredTransports = transports.map((transport, index) => {
		    return {
		      id: transport.id,
          order: index
		    }
		  })

      const response = await TransportApi.updateOrders(filteredTransports)
		  return response
		} catch (err) {
			throw err
		}	finally {
		}
	},

	async updateOptionOrders ({}, options) {
	  try {
	    const filteredOptions = options.map((option, index) => {
	      return {
	        id: option.id,
	        order: index
	      }
	    })
	    const response = await TransportApi.updateOptionOrders(filteredOptions)
	    return response
	  } catch (err) {
	    throw err
	  } finally {
	  }
	},

	/**
	 * Remove user 
	 */
	async remove ({}, transport_id) {
		try {
      const transport = await TransportApi.delete(transport_id)
      return transport
		} catch (err) {
			throw err
		}	finally {
		}
	},
}
