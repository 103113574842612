const non_navigation_items = [
	{
		path: '/',
		redirect: '/pakolliset_osiot'
	},
	{
		path: '/pakolliset_osiot/muokkaa',
		redirect: '/pakolliset_osiot/muokkaa/kysymykset'
	},
	{
		path: '/kehykset/muokkaa',
		redirect: '/kehykset/muokkaa/osiot'
	},
	{
    path: '/kirjaudu',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../../views/LoginView.vue'),
		meta: {
			requiresAuth: false 
		}
  },
	{
		path: '/pakolliset_osiot/muokkaa',
		name: 'survey_sections_edit',
		component: () => import('@/views/admin/survey-section/SurveySectionEditView'),
		children: [
			{
				path: 'kysymykset',
				component: () => import('@/views/admin/survey-section/SurveySectionEditQuestionsView'),
				meta: {
					title: 'Kysymykset',
					parent: 'survey_sections_edit'
				}
			},
			{
				path: 'kohteiden_tyypit',
				component: () => import('@/views/admin/survey-section/SurveySectionEditTargetsView'),
				meta: {
					title: 'Kohteiden Tyypit',
					parent: 'survey_sections_edit'
				}
			},
			{
				path: 'asetukset',
				component: () => import('@/views/admin/survey-section/SurveySectionEditSettingsView'),
				meta: {
					title: 'Asetukset',
					parent: 'survey_sections_edit'
				}
			}
		],
		meta: {
			title: 'Muokkaa pakollista osiota',
			parent: 'survey_section_index',
			requiresAuth: true
		}
	},
  {
    path: '/pakolliset_osiot/muokkaa/esikatsele',
    name: 'survey_sections_edit_preview',
    component: () => import('@/views/admin/survey-section/SurveySectionEditPreviewView'),
		meta: {
			title: 'Esikatsele',
			parent: 'survey_sections_edit',
			requiresAuth: true
		}
  },
  {
    path: '/kehykset/muokkaa',
    name: 'frame_edit',
    component: () => import('@/views/admin/frame/FrameEditView'),
		meta: {
			title: 'Muokkaa kehystä',
			parent: 'frame_index',
			requiresAuth: true
		},
		children: [
			{
				path: 'osiot',
				component: () => import('@/views/admin/frame/FrameEditSectionsView'),
				meta: {
					title: 'Kehyksen osiot',
					parent: 'frame_edit'
				}
			},
			{
				path: 'kayttajat',
				component: () => import('@/views/admin/frame/FrameEditUsersView'),
				meta: {
					title: 'Kehyksen käyttäjät',
					parent: 'frame_edit'
				}
			}
		],
  },
	{
		path: '/yleiset_kysymyssivut/muokkaa',
		name: 'common_segment_edit',
		component: () => import('@/views/admin/common-segment/CommonSegmentEditView'),
		meta: {
			title: 'Muokkaa yleistä kysymystä',
			parent: 'common_segment_index',
			requiresAuth: true
		},
    children: [
    	{
    		path: 'kysymykset',
		    name: 'common_segment_edit_questions',
    		component: () => import('@/views/admin/common-segment/CommonSegmentEditQuestionView'),
				meta: {
					title: 'Kysymykset',
					parent: 'common_segment_edit'
				}
    	},
    	{
    		path: 'asetukset',
    		component: () => import('@/views/admin/common-segment/CommonSegmentEditSettingsView'),
				meta: {
					title: 'Asetukset',
					parent: 'common_segment_edit'
				}
    	},
    ],
	},
	{
		path: '/kayttajat/muokkaa',
		name: 'user_edit',
		component: () => import('@/views/admin/user/UserEditView'),
		meta: {
			title: 'Muokkaa käyttäjän tietoja',
			parent: 'user_index',
			requiresAuth: true
		}
	},
	{
		path: '/kulkuvalineet/muokkaa',
		name: 'transport_edit',
		component: () => import('@/views/admin/transport/TransportEditView'),
		meta: {
			title: 'Muokkaa kulkuvälineen tietoja',
			parent: 'transport_index',
			requiresAuth: true
		}
	},

	// Add new non-navigation route
	{
		path: '/kyselyryhma/:id',
		name: 'group_view_single',
		component: () => import('@/views/admin/survey-group/SurveyGroupEditView'),
		meta: {
			sort_order: 5,
			title: 'Kyselyryhmä',
		}
	},
	{
		path: '/kooste/:id',
		name: 'compilation_view_single',
		component: () => import('@/views/admin/management-table/SingleCompilationView'),
		meta: {
			sort_order: 5,
			title: 'Kooste',
			icon: 'mdi-translate',
			requiresAuth: true
		}
	},
]

const navigation_items = [
	{
		path: '/kayttajat',
		name: 'user_index',
		component: () => import('@/views/admin/user/UserIndexView'),
		meta: {
			sort_order: 1,
			title: 'Käyttäjät',
			icon: 'mdi-account',
			requiresAuth: true
		}
	},
	{
    path: '/kehykset',
    name: 'frame_index',
    component: () => import('@/views/admin/frame/FrameIndexView.vue'),
		meta: {
			sort_order: 2,
			title: 'Kehykset',
			icon: 'mdi-text-box-plus',
			requiresAuth: true
		}
  },
  {
    path: '/pakolliset_osiot',
    name: 'survey_section_index',
    component: () => import('@/views/admin/survey-section/SurveySectionIndexView.vue'),
		meta: {
			sort_order: 3,
			title: 'Pakolliset osiot',
			icon: 'mdi-view-list',
			requiresAuth: true
		}
  },
  {
    path: '/kyselyryhmat',
    name: 'survey_group_index',
    component: () => import('@/views/admin/survey-group/SurveyGroupIndexView.vue'),
		meta: {
			sort_order: 3,
			title: 'Kyselyryhmät',
			icon: 'mdi-view-list',
			requiresAuth: true
		}
  },
	{
		path: '/yleiset_kysymyssivut',
		name: 'common_segment_index',
		component: () => import('@/views/admin/common-segment/CommonSegmentIndex'),
		meta: {
			sort_order: 4,
			title: 'Yleiset kysymyssivut',
			icon: 'mdi-text-box',
			requiresAuth: true
		}
	},
	{
		path: '/kulkuvalineet',
		name: 'transport_index',
		component: () => import('@/views/admin/transport/TransportIndexView'),
		meta: {
			sort_order: 1,
			title: 'Kulkuvälineet',
			icon: 'mdi-train-car',
			requiresAuth: true
		}
	},
	{
		path: '/kohteiden_tyypit',
		name: 'target_types',
		component: () => import('@/views/admin/target-type/TargetTypeEditView'),
		meta: {
			sort_order: 5,
			title: 'Kohteiden tyypit',
			icon: 'mdi-text-box',
			requiresAuth: true
		}
	},
	{
		path: '/kaannokset',
		name: 'static_translations',
		component: () => import('@/views/admin/StaticTranslationsView'),
		meta: {
			sort_order: 5,
			title: 'Staattiset käännökset',
			icon: 'mdi-translate',
			requiresAuth: true
		}
	},
	//HALLINTATAULUKKO NAV
	{
		path: '/admin_kysymykset',
		name: 'admin_questions_view',
		component: () => import('@/views/admin/management-table/AdminQuestionsView'),
		meta: {
			sort_order: 5,
			title: 'Adminin yleiset kysymykset',
			icon: 'mdi-view-list',
			requiresAuth: true
		}
	},
	{
		path: '/subscriber_kysymykset',
		name: 'subscriber_questions_view',
		component: () => import('@/views/admin/management-table/SubscriberQuestionsView'),
		meta: {
			sort_order: 5,
			title: 'Subscriber kysymykset',
			icon: 'mdi-view-list',
			requiresAuth: true
		}
	},
	{
		path: '/vastausten_vakiointi',
		name: 'answer_standardization_view',
		component: () => import('@/views/admin/management-table/AnswerStandardizationView'),
		meta: {
			sort_order: 5,
			title: 'Vastausten vakiointi',
			icon: 'mdi-view-list',
			requiresAuth: true
		}
	},
	{
		path: '/koosteet',
		name: 'compilation_view',
		component: () => import('@/views/admin/management-table/CompilationView'),
		meta: {
			sort_order: 5,
			title: 'Koosteet',
			icon: 'mdi-view-list',
			requiresAuth: true
		}
	},
	{
		path: '/kysymysten_luokittelu',
		name: 'question_classification_view',
		component: () => import('@/views/admin/management-table/QuestionClassificationView'),
		meta: {
			sort_order: 5,
			title: 'Kysymysten luokittelu',
			icon: 'mdi-view-list',
			requiresAuth: true
		}
	},
	{
		path: '/grouping',
		name: 'grouping_view',
		component: () => import('@/views/admin/management-table/GroupingView'),
		meta: {
			sort_order: 5,
			title: 'Grouping',
			icon: 'mdi-view-list',
			requiresAuth: true
		}
	},

  // Add navigation route
]

const navigation_sub_items =  [
	//Add sub-navigation route
]

const routes = [
	...non_navigation_items,
	...navigation_items,
	...navigation_sub_items
]

export {
	navigation_items,
	navigation_sub_items,
	routes
}


