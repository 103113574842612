import mixinGetters from '@/store/mixins/preview/getters'
import mixinMutations from '@/store/mixins/preview/mutations'
import mixinActions from '@/store/mixins/preview/actions'

export default {
	namespaced: true,
	state: {
		activePage: 'start',
		segments: [],
		activeSegment: null,
		history: [],
		invalidFields: [],
		answers: {}
	},
	getters: {
		editSource (state, getters, rootState, rootGetters) {
			return rootGetters.editSources.section
		},
		...mixinGetters, 
		...{
			activePage: state => state.activePage,
			activeSegment: state => state.activeSegment,
			isFirstSegment: state => state.activeSegment.id === state.segments[0].id,
			segmentFields: (state, getters, rootState, rootGetters) => (segment) => {
				if (segment.is_common) {
					return rootGetters['commonSegment/field/segmentFields'](segment.fields)
				} else {
					return rootGetters['surveySection/editing/field/segmentFields'](segment.fields)
				}
			}
		}
  },
  mutations: {
		...mixinMutations
  },
  actions: {
		...mixinActions
	}
}
