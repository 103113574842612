import AnswersApi from '@/services/api/admin/AnswersApi'

export default {
	/**
	 * Get all non-admin fields 
	 */
	async all ({ commit }, ) {
		try {
			const answers = await AnswersApi.all()
			commit('setAnswers', answers)
		} catch (err) {
			throw err
		}	
	}
}