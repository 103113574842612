import field from './field'
import option from './option'
import mixinMutations from '@/store/mixins/common-segment/editing/mutations'
import actions from './actions'

export default {
	namespaced: true,
	state: {
		data: {},
		notValid: {
			fi: false,
			en: false
		},
		locale: 'fi'
	},
	getters: {
		data: state => state.data,
		hasInvalidValue: (state) => (locale, segment_id) => {
			if(!state.notValid.hasOwnProperty(locale)) return false
			return state.notValid[locale]
		},
		hasSomeInvalidValue (state) {
			let hasInvalidValues =  false
			Object.values(state.notValid).forEach(value => {
				if (value) hasInvalidValues = true
			})
			return hasInvalidValues
		},
		hasInvalidValues (state, getters) {
		const hasInvalidFields = getters['field/hasInvalidValues']
		const hasInvalidOptions = getters['option/hasInvalidValues']
		return getters['hasSomeInvalidValue'] || hasInvalidFields || hasInvalidOptions
		},
		locale: state => state.locale
  },
	mutations: {
		...mixinMutations
	},
	actions: {
		...actions
	},
	modules: {
		field: field,
		option: option
	}
}
