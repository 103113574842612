import CommonSegmentApi from '@/services/api/admin/CommonSegmentApi'
import Normalizer from '@/services/Normalizer'
export default {
	/**
	 * Update segment, and segments's fields and options 
	 * @param {} 
	 */
	async update ({ state, commit, getters, dispatch }) {
		const segment = getters['data']
		const fields = getters['field/all']
		const options = getters['option/all']
		const languages = getters['data'].languages
		dispatch(`field/removeExtraOptions`)
		const response = await CommonSegmentApi.update(
			segment.id,
			{
				segment: segment,
				fields: fields,
				options: options,
				categories: segment.categories,
				removed_options: state.option.removed,
				removed_fields: state.field.removed,
				languages: languages
			}
		)	
		dispatch('setEditingData', response)
		return response
	},
	async updateSettings ({ state, commit, getters, dispatch }) {
		const segment_id = getters['data'].id
		const languages = getters['data'].languages
		const response = await CommonSegmentApi.updateSettings(
			segment_id,
			{
				languages: languages
			}
		)	
	},
	/**
	 * Set segments with api data 
	 * @param {Object} segment
	 */
	setEditingData ({ commit }, segment) {
		const {segments, fields, options} = Normalizer.getCommonSegmentEntities([segment])	
		commit('setEditing', Object.values(segments)[0])
		let segmentFields = fields ? fields : {}
		commit('field/setFields', segmentFields)
		let fieldOptions = options ? options : {}
		commit('option/setOptions', fieldOptions)
		commit('clearNotValid')
	},
	/**
	 * Edit segment's translation value
	 * @param {Object} payload 
	 */
	editTranslationValue({state, commit, getters, rootGetters}, payload) {
		commit('editTranslationValue', {
			key: payload.key,
			value: payload.value,
			locale: getters.locale 
		})
	},
	/**
	 * Validate segment's values 
	 */
	validateSegment ({ state, commit }, ) {
		Object.keys(state.data.translations).forEach(key => {
			if (state.data.translations[key].title === '') {
				commit('setNotValid', key)
			}
		})
	},
}
