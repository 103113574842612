import UserApi from '@/services/api/admin/UserApi'

export default {
	/**
	 * Get all non-admin users 
	 */
	async all ({ commit }, ) {
		try {
			const users = await UserApi.all()
			commit('setUsers', users)
		} catch (err) {
			throw err
		}	
	},
	/**
	 * Remove user 
	 */
	async remove ({}, user_id) {
		try {
			 return await UserApi.delete(user_id)
		} catch (err) {
			throw err
		}	finally {
		}
	},
}
