import FrameApi from '@/services/api/admin/FrameApi'

export default {
	/**
	 * Get frames 
	 * @param {} 
	 */
	async all ({ commit }, ) {
		try {
			const frames = await FrameApi.all()
			commit('setFrames', frames)
		} catch (err) {
			throw err
		}
	},
	/**
	 * Store frame 
	 * @param {} 
	 */
	async store ({ state }) {
		try {
			const frame = await FrameApi.store(state.creating)
			return frame
		} catch (err) {
			throw err
		}
	},	
	/**
	 * Store frame 
	 * @param {String} frame_id 
	 */
	async remove ({ state }, frame_id) {
		try {
			const frame = await FrameApi.delete(frame_id)
			return frame
		} catch (err) {
			throw err
		}
	},	
}
