import mutations from './mutations'
import actions from './actions'

export default {
	namespaced: true,
	state: {
		frame: {},
		start: ['jotain'],
		end: [1,2],
		users: []
	},
	getters: {
		frame: state => state.frame,
		start: state => state.start,
		end: state => state.end,
		users: state => state.users
  },
  mutations: mutations,
  actions: actions 
}
