import Vue from 'vue';
import axios from 'axios'
import App from './AdminApp.vue';
import store from './store/admin';
import vuetify from './plugins/vuetify';
import i18n from './i18n'
import _ from 'lodash';

Vue.config.productionTip = false;

new Vue({
  store,
  vuetify,
	i18n,
  render: (h) => h(App),
}).$mount('#app');
