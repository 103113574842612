import actions from './actions'
import mutations from './mutations'

export default {
	namespaced: true,
	state: {
		all: []
	},
	getters: {
		all: state => state.all
  },
  mutations: mutations,
	actions: actions,
}
