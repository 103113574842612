import Http from '@/services/AdminHttp'

export default {
	async all () {
		try {
			const response = await Http.get('api/admin/frames') 
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Get frame by id
	 *
	 * @param {String} id uuid
	 * @return {Object} 
	 */
	async show (id) {
		try {
			const response = await Http.get(`api/admin/frames/${id}`) 
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Create a new frame
	 *
	 * @param {Object} 
	 * @return {Object} 
	 */
	async store (data) {
		try {
			const response = await Http.post('api/admin/frames', data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update frame's data
	 *
	 * @param {String} id
	 * @param {Object} data
	 * @return {Object} 
	 */
	async update (id, data) {
		try {
			const response = await Http.patch(`api/admin/frames/${id}`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},

	/**
	 * Delete chosen frame
	 *
	 * @param {String} id
	 */
	async delete (id) {
		try {
			return await Http.delete(`api/admin/frames/${id}`)
		} catch (err) {
			throw err
		}
	},
	/**
	 * Get frame's users 
	 *
	 * @param {String} id uuid
	 * @return {Object} 
	 */
	async users (id) {
		try {
			const response = await Http.get(`api/admin/frames/${id}/users`) 
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update frame's users
	 *
	 * @param {String} id
	 * @param {Object} data
	 * @return {Object} 
	 */
	async updateUsers (id, data) {
		try {
			const response = await Http.patch(`api/admin/frames/${id}/users`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},
}
