export default {
	/**
	 * Edit user's value
	 * @param {Object} payload 
	 */
	editValue(state, payload) {
		state.transport = {
			...state.transport,
			[payload.key]: payload.value
		}
	},

	editTranslationValue(state, payload) {
	  state.transport = {
	    ...state.transport,
	    translations: {
	      ...state.transport.translations,
	      [payload.locale]: {
	        name: payload.value
	      }
	    }
	  }
	},
	/**
	 * Edit
	 * @param {Object} payload 
	 */
	editOptionValue(state, payload) {
		state.option = {
			...state.option,
			[payload.key]: payload.value
		}
	},

	editOptionTranslationValue(state, payload) {
	  state.option = {
	    ...state.option,
	    translations: {
	      ...state.option.translations,
	      [payload.locale]: {
	        name: payload.value
	      }
	    }
	  }
	},

	clearTransportCreateFormData(state) {
	  state.transport = {
		  translations: {
		    en: {
		      name: null
		    },
		    fi: {
		      name: null
		    }
		  },
		  icon: null,
		  emission: null,
		  transit: null
	  }
	},
	clearTransportOptionCreateFormData(state) {
	  state.option = {
		  translations: {
		    en: {
		      name: null
		    },
		    fi: {
		      name: null
		    }
		  },
		  emission: null,
		  transit: null
	  }
	}
}
