export default {
	/**
	 * Set all types
	 * @param {Array} types 
	 */
	setAll(state, types) {
		state.all = types
	},
	/**
	 * Edit editing value
	 * @param {Object} payload 
	 */
	editEditingValue(state, payload) {
		state.editing =  {
			...state.editing,
			[payload.key]: payload.value
		}
	},
	/**
	 * Edit creating value
	 * @param {Object} payload 
	 */
	editCreatingValue(state, payload) {
		state.creating =  {
			...state.creating,
			[payload.key]: payload.value
		}
	},
	/**
	 * Set editin target type
	 * @param {Object} targetType 
	 */
	setEditingItem(state, targetType) {
		state.editing = targetType
	},
}
