import Http from '@/services/AdminHttp'

export default {
	async all () {
		try {
			const response = await Http.get('api/admin/survey_sections') 
			return response ?  response.data : []
		} catch (err) {
			throw err
		}
	},
	/**
	 * Get survey section by id
	 *
	 * @param {String} id uuid
	 * @return {Object} 
	 */
	async show (id) {
		try {
			const response = await Http.get(`api/admin/survey_sections/${id}`) 
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Get section's types
	 *
	 * @param {String} id uuid
	 * @return {Object} 
	 */
	async types (id) {
		try {
			const response = await Http.get(`api/admin/survey_sections/${id}/types`) 
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Create a new survey section
	 *
	 * @param {Object} 
	 * @return {Object} 
	 */
	async store (data) {
		try {
			const response = await Http.post('api/admin/survey_sections', data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update survey section's data
	 *
	 * @param {String} id
	 * @param {Object} data
	 * @return {Object} 
	 */
	async update (id, data) {
		try {
			const response = await Http.patch(`api/admin/survey_sections/${id}`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update survey section's types
	 *
	 * @param {String} id
	 * @param {Object} data
	 * @return {Object} 
	 */
	async updateTypes (id, data) {
		try {
			const response = await Http.patch(`api/admin/survey_sections/${id}/types`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update survey section's segments, fields and options
	 *
	 * @param {String} id id of the survey 
	 * @param {Object} data segments, fields and options 
	 * @return {Object}
	 */
	async updateParts (id, data) {
		try {
			const response = await Http.patch(`api/admin/survey_sections/${id}/survey-parts`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Delete chosen survey section
	 *
	 * @param {String} id
	 */
	async delete (id) {
		try {
			return await Http.delete(`api/admin/survey_sections/${id}`)
		} catch (err) {
			throw err
		}
	}
}
