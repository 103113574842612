import StaticTranslationApi from '@/services/api/admin/StaticTranslationApi'
export default {
  async all ({ commit }) {
    try {
      const static_translations = await StaticTranslationApi.all()
      commit('setAll', static_translations)
    } catch (err) {
      throw err
    }
  },
  async updateTranslations ({ commit, getters }) {
    try {
      await StaticTranslationApi.update({translations: getters['all']})
    } catch (err) {
      throw err
    }
  },
}
