import UserApi from '@/services/api/admin/UserApi'

export default {
	/**
	 * Set user's data 
	 * @param {Object} user
	 */
	setEditingData ({ commit }, user) {
		commit('setData', user)
	},
	/**
	 * Update user's data
	 */
	async update ({ state, commit }) {
		try {
			 return await UserApi.update(state.data.id, state.data)
		} catch (err) {
			throw err
		}	finally {
		}
	},
}
