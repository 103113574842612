import Http from '@/services/AdminHttp'

export default {
	async all () {
		try {
			const response = await Http.get('api/admin/subscriber_fields') 
			return response.data
		} catch (err) {
			throw err
		}
	},
	
}
