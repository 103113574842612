export default {
	/**
	 * Set all users
	 * @param {Array} users 
	 */
	setTransports(state, transports) {
		state.all = transports
	},

  addTransport(state, transport_id) {
    if(!state.transports.includes(transport_id)) {
      state.transports.push(transport_id)
    }
  },

	/**
	 * Remove user from list
	 * @param {String} user_id 
	 */
	removeTransport(state, transport_id) {
		const index = state.transports.indexOf(transport_id)
		if (index !== -1) {
			state.transports.splice(index, 1)
		}
	},
}
