import UserApi from '@/services/api/admin/UserApi'

export default {
	/**
	 * Store a new user 
	 */
	async store ({ state, commit }) {
		try {
			 return await UserApi.store(state.data)
		} catch (err) {
			throw err
		}	finally {
		}
	},
}
