import Vue from 'vue'
import Vuex from 'vuex'
import { createStore } from 'vuex-extensions'
import modules from './modules'
import sharedModules from '@/store/shared-modules'
import VuexPersistence from "vuex-persist"
import localforage from 'localforage'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
	storage: localforage,
	key: 'salmiadmin',
	asyncStorage: true,
  })

export default createStore(Vuex.Store, {
  state: {
		api: 'admin',
		editSources: {
			survey: {
				type: 'survey',
				base: 'survey/editing',
				segment: 'survey/editing/segment',
				field: 'survey/editing/field',
				option: 'survey/editing/option',
				jump: 'survey/editing/jump',
				ruleGroup: 'survey/editing/jump/ruleGroup',
				rule: 'survey/editing/jump/rule',
				create: 'survey/creating',
				preview: 'survey/editing/preview',
			},
			template: {
				type: 'template',
				base: 'surveyTemplate/editing',
				segment: 'surveyTemplate/editing/segment',
				field: 'surveyTemplate/editing/field',
				option: 'surveyTemplate/editing/option',
				jump: 'surveyTemplate/editing/jump',
				ruleGroup: 'surveyTemplate/editing/jump/ruleGroup',
				rule: 'surveyTemplate/editing/jump/rule',
				create: 'surveyTemplate/creating',
				preview: 'surveyTemplate/editing/preview',
			},
			section: {
				type: 'section',
				base: 'surveySection/editing',
				segment: 'surveySection/editing/segment',
				field: 'surveySection/editing/field',
				option: 'surveySection/editing/option',
				jump: 'surveySection/editing/jump',
				ruleGroup: 'surveySection/editing/jump/ruleGroup',
				rule: 'surveySection/editing/jump/rule',
				create: 'surveySection/creating',
				keyForJump: 'survey_section_id',
				preview: 'surveySection/editing/preview',
			},
			commonSegment: {
				base: 'commonSegment',
				type: 'commonSegment', 
				segment: 'commonSegment',
				field: 'commonSegment/field',
				option: 'commonSegment/option',
			},
			commonSegmentEdit: {
				base: 'commonSegment/editing',
				type: 'segment-edit',
				segment: 'commonSegment/editing',
				field: 'commonSegment/editing/field',
				option: 'commonSegment/editing/option'
			},
		}
  },
	getters: {
		editSources: state => state.editSources
	},
	modules: {
		...modules,
		...sharedModules
	},
	plugins: [vuexLocal.plugin]
});
