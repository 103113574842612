import TypeApi from '@/services/api/admin/TargetTypeApi'

export default {
	/**
	 * Get all target types 
	 */
	async all ({ commit }, ) {
		try {
			const types = await TypeApi.all()
			commit('setAll', types)
		} catch (err) {
			throw err
		}	
	},
	/**
	 * Create target type 
	 */
	async store ({state}) {
		try {
			const type = await TypeApi.store(state.creating)
		} catch (err) {
			throw err
		}	
	},
	/**
	 * Create target type 
	 */
	async update ({state}) {
		try {
			const type = await TypeApi.update(state.editing.id, state.editing)
		} catch (err) {
			throw err
		}	
	},
	/**
	 * Remove target type 
	 */
	async remove ({}, type_id) {
		try {
			const types = await TypeApi.delete(type_id)
		} catch (err) {
			throw err
		}	
	},
}
