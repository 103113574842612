import Http from '@/services/AdminHttp'

export default {
	async all () {
		try {
			const response = await Http.get('api/admin/common_segments') 
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Create a new common segment
	 *
	 * @param {Object} 
	 * @return {Object} 
	 */
	async store (data) {
		try {
			const response = await Http.post('api/admin/common_segments', data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update common segment and it's field and options
	 *
	 * @param {String} id id of the segment
	 * @param {Object} data segment, fields and options 
	 * @return {Object}
	 */
	async update (id, data) {
		try {
			const response = await Http.patch(`api/admin/common_segments/${id}`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	async updateSettings (id, data) {
		try {
			const response = await Http.patch(`api/admin/common_segments/${id}/settings`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Delete chosen common segment
	 *
	 * @param {String} id
	 */
	async delete (id) {
		try {
			const response =  await Http.delete(`api/admin/common_segments/${id}`)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Copy chosen common segment
	 *
	 * @param {String} id
	 */
	async copy (id) {
		try {
			const response =  await Http.post(`api/subscriber/common_segments/${id}/copy`)
			return response.data
		} catch (err) {
			throw err
		}
	}
}
