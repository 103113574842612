import mutations from './mutations'
import actions from './actions'

export default {
	namespaced: true,
	state: {
		data: {}
	},
	getters: {
		user: state => state.data
  },
  mutations: mutations,
  actions: actions
}
