import FrameApi from '@/services/api/admin/FrameApi'

export default {
	/**
	 * Set frame data, start sections and end sections 
	 * @param {Object} frame
	 */
	setEditing ({ commit }, frame) {
		const startSections = frame.survey_sections.filter(section => section.pivot.position === 'start')
		const endSections = frame.survey_sections.filter(section => section.pivot.position === 'end')
		commit('setStart', startSections)
		commit('setEnd', endSections)
		commit('setFrame', frame)
	},
	/**
	 * Update frame sections 
	 * @param {} 
	 */
	async update ({ state, dispatch }, ) {
		try {
			const startIds = state.start.map(section => section.id)
			const endIds = state.end.map(section => section.id)
			const frame = await FrameApi.update(state.frame.id, {
				...state.frame,
				start: startIds,
				end: endIds
			})
			dispatch('setEditing', frame)
		} catch (err) {
			throw err
		}		
	},
	/**
	 * Get sections' target users 
	 * @param {String} section_id
	 */
	async getUsers ({ state, commit }) {
		try {
			const users = await FrameApi.users(state.frame.id)
			let usersIds = users.map(user => user.id)
			commit('setUsersList', usersIds)
		} catch (err) {
			throw err
		}	
	},
	/**
	 * Get sections' target users
	 * @param {String} section_id
	 */
	async updateUsers ({ state, commit }) {
		try {
			const users = await FrameApi.updateUsers(state.frame.id, {
				users: state.users
			})
			let userIds = users.map(user => user.id)
			commit('setUsersList', userIds)
		} catch (err) {
			throw err
		}	
	},
}
