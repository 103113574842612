import Http from '@/services/AdminHttp'

export default {
  async store (transport_id, option) {
    try {
      const response = await Http.post(`api/admin/transports/${transport_id}/options`, option)
			let options = response.data.map(option => {
			  let option_translations = {}

			  option.translations.forEach(translation => {
			    option_translations = {
			      ...option_translations,
			      [translation.locale]: {
			        name: translation.name
			      }
			    }
			  })

			  return {
			    ...option,
			    translations: option_translations
			  }
			})

      return options
    } catch (err) {
      throw err
    } finally {
    }
  },

  async update (transport_id, option) {
    try {
      const response = await Http.patch(`api/admin/transports/${transport_id}/options/${option.id}`, option)
			let options = response.data.map(option => {
			  let option_translations = {}

			  option.translations.forEach(translation => {
			    option_translations = {
			      ...option_translations,
			      [translation.locale]: {
			        name: translation.name
			      }
			    }
			  })

			  return {
			    ...option,
			    translations: option_translations
			  }
			})

      return options
    } catch (err) {
      throw err
    } finally {
    }
  },

  async delete (transport_id, option_id) {
    try {
      const response = await Http.delete(`api/admin/transports/${transport_id}/options/${option_id}`)
			let options = response.data.map(option => {
			  let option_translations = {}

			  option.translations.forEach(translation => {
			    option_translations = {
			      ...option_translations,
			      [translation.locale]: {
			        name: translation.name
			      }
			    }
			  })

			  return {
			    ...option,
			    translations: option_translations
			  }
			})
      return options
    } catch (err) {
      throw err
    } finally {
    }
  }
}
