import TransportApi from '@/services/api/admin/TransportApi'
import TransportOptionApi from '@/services/api/admin/TransportOptionApi'

export default {
	/**
	 * Store a new user 
	 */
	async store ({ state, commit }) {
		try {
			 return await TransportApi.store(state.transport)
		} catch (err) {
			throw err
		}	finally {
		}
	},

	/**
	 * Store a new user 
	 */
	async storeOption ({ state, commit }, transport_id) {
		try {
			 return await TransportOptionApi.store(transport_id, state.option)
		} catch (err) {
			throw err
		}	finally {
		}
	},
}
