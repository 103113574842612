export default {
	/**
	 * Set frame's data
	 * @param {Object} frame 
	 */
	setFrame(state, frame) {
		state.frame = frame
	},
	/**
	 * Set start array 
	 * @param {Array}  payload 
	 */
	setStart(state,  payload) {
		state.start = payload	
	},
	/**
	 * Set end array 
	 * @param {Array}  payload 
	 */
	setEnd(state,  payload) {
		state.end = payload	
	},
	/**
	 * Edit frame's value
	 * @param {Object} payload 
	 */
	editFrameValue(state, payload) {
		state.frame = {
			...state.frame,
			[payload.key]: payload.value
		}
	},
	/**
	 * Set users list
	 * @param {Array} users 
	 */
	setUsersList(state, users) {
		state.users = users
	},
	/**
	 * Add user to the list
	 * @param {String} user_id 
	 */
	addUser(state, user_id) {
		if (!state.users.includes(user_id)) {
			state.users.push(user_id) 
		}
	},
	/**
	 * Remove user from list
	 * @param {String} user_id 
	 */
	removeUser(state, user_id) {
		const index = state.users.indexOf(user_id)
		if (index !== -1) {
			state.users.splice(index, 1)
		}
	},
}
