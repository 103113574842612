import mutations from './mutations'
import actions from './actions'

export default {
	namespaced: true,
	state: {
	  transport: {
	    tranlations: {
	      en: {
	        name: null
	      },
	      fi: {
	        name: null
	      }
	    },
	    icon: null,
	    emission: null
	  },
	  option: {
	    tranlations: {
	      en: {
	        name: null
	      },
	      fi: {
	        name: null
	      }
	    },
	    emission: null
	  }
	},
	getters: {
		transport: state => state.transport,
		option: state => state.option
  },
	mutations: mutations,
	actions: actions
}
