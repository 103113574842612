import Http from '@/services/AdminHttp'

export default {
	async all () {
		try {
			const response = await Http.get('api/admin/target_types') 
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Get target type by id
	 *
	 * @param {String} id uuid
	 * @return {Object} 
	 */
	async show (id) {
		try {
			const response = await Http.get(`api/admin/target_types/${id}`) 
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Create a new target type
	 *
	 * @param {Object} 
	 * @return {Object} 
	 */
	async store (data) {
		try {
			const response = await Http.post('api/admin/target_types', data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update target type's data
	 *
	 * @param {String} id
	 * @param {Object} data
	 * @return {Object} 
	 */
	async update (id, data) {
		try {
			const response = await Http.patch(`api/admin/target_types/${id}`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},

	/**
	 * Delete chosen target type
	 *
	 * @param {String} id
	 */
	async delete (id) {
		try {
			return await Http.delete(`api/admin/target_types/${id}`)
		} catch (err) {
			throw err
		}
	}
}
