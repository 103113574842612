import SurveyGroupApi from '../../../../services/api/admin/SurveyGroupApi'
export default {
  async all ({ commit }) {
    try {
      const groups = await SurveyGroupApi.all()
      commit('setAll', groups)
    } catch (err) {
      throw err
    }
  }
}
