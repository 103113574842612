import mixinMutations from '@/store/mixins/rule-group/mutations'
import mixinActions from '@/store/mixins/rule-group/actions'
import mixinGetters from '@/store/mixins/rule-group/getters'

export default {
	namespaced: true,
	state: {
		all: {},
		removed: []
	},
	getters: {
		...mixinGetters,
		editSource (state, getters, rootState, rootGetters) {
			return rootGetters.editSources.section
		}
	},
  mutations: mixinMutations,
	actions: {
		...mixinActions
	},
}
