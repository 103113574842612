import SubscriberFieldsApi from '@/services/api/admin/SubscriberFieldsApi'

export default {
	/**
	 * Get all non-admin fields 
	 */
	async all ({ commit }, ) {
		try {
			const subscriberFields = await SubscriberFieldsApi.all()
			commit('setSubscriberFields', subscriberFields)
		} catch (err) {
			throw err
		}	
	}
}
